class MobileMenu {
	constructor() {
		this.body = document.body
		this.html = document.getElementsByTagName("html")[0]
		this.toggleNav = this.toggleNav.bind(this)
		this.toggleSubMenu = this.toggleSubMenu.bind(this)
	}

	init() {
		this.isMenuOpen = false
		this.nav = document.querySelector(".secondary-nav__container")
		this.openButton = document.querySelector(".secondary-nav__button")
		this.openBurger = document.querySelector(".secondary-nav__burger")
		this.wholeMenu = document.querySelector(".secondary-nav__container")
		this.overlay = document.querySelector(".overlay")
		this.language = document.querySelector(".header__language-container")
		this.search = document.querySelector(".header__search-container")

		this.isSubMenuOpen = false
		this.menu = document.querySelector(".menu-item")
		this.subMenu = document.querySelector(".sub-menu")
		this.openSubMenu = document.querySelectorAll(".menu-item-has-children > a")
	}

	start() {
		this.openButton.addEventListener("click", this.toggleNav)
		this.openSubMenu.forEach(el => {
			el.addEventListener("click", this.toggleSubMenu)
		})
		this.overlay.addEventListener("click", this.toggleNav)
	}
	stop() {
		this.openButton.removeEventListener("click", this.toggleMenu)
		this.openSubMenu.forEach(el => {
			el.removeEventListener("click", this.toggleSubMenu)
		})
	}

	toggleNav() {
		if (this.isMenuOpen == false) {
			this.body.classList.toggle("h--no-scroll")
			this.html.classList.toggle("h--no-scroll")
			this.nav.classList.toggle("secondary-nav__container--active")
			this.openButton.classList.toggle("secondary-nav__button--active")
			this.openBurger.classList.toggle("secondary-nav__burger--active")
			this.overlay.classList.toggle("overlay--active")
			this.search.classList.toggle("header__search-container--active")
			this.language.classList.toggle("header__language-container--active")
			this.isMenuOpen = true
		} else {
			this.nav.classList.toggle("secondary-nav__container--active")
			this.openButton.classList.toggle("secondary-nav__button--active")
			this.openBurger.classList.toggle("secondary-nav__burger--active")
			this.overlay.classList.toggle("overlay--active")
			this.search.classList.toggle("header__search-container--active")
			this.language.classList.toggle("header__language-container--active")
			this.body.classList.toggle("h--no-scroll")
			this.html.classList.toggle("h--no-scroll")
			this.isMenuOpen = false
		}
	}

	toggleSubMenu(event) {
		const sub = event.target.closest(".menu-item")
		const children = Array.from(sub.children)

		sub.classList.toggle("active")
		children.forEach(function (el) {
			if (el.classList.contains("sub-menu")) {
				el.classList.toggle("sub-menu--active")
			}
		})
	}
}

export default MobileMenu
