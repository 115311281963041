import Swiper from "swiper/bundle"
import "swiper/swiper-bundle.css"

export default class Slider {
	constructor() {
		if (document.querySelector(".slider__container")) {
			// Actually initialize the glide / slider script
			var swiper = new Swiper(".slider__container", {
				autoplay: false,
				/* autoplay: {
					delay: 1000,
					disableOnInteraction: false,
					pauseOnMouseEnter: true,
				}, */
				slidersPerView: 1,
				loop: true,
				speed: 1000,

				// If we need pagination
				pagination: {
					el: ".slider__slider-bullets",
					clickable: true,
				},

				// Navigation arrows
				navigation: {
					nextEl: ".slider__slider-arrow--next",
					prevEl: ".slider__slider-arrow--prev",
				},

				// And if we need scrollbar
				scrollbar: {
					el: ".swiper-scrollbar",
				},
			})
		}
	}
}
