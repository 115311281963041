import "../css/style.scss"

import AOS, { init } from "aos"
import Nav from "./modules/Nav"
import MobileMenu from "./modules/MobileMenu"
import Rellax from "rellax"
import Slider from "./modules/Slider"

const mobileMenu = new MobileMenu()
const slider = new Slider()
mobileMenu.init()
mobileMenu.start()
const nav = new Nav()
nav.init()
nav.start()
var rellax = new Rellax(".rellax")

// Allow new JS and CSS to load in browser without a traditional page refresh
if (module.hot) {
	module.hot.accept()
}

/* Animate on scroll */
AOS.init({
	// Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
	offset: 100, // offset (in px) from the original trigger point
	delay: 0, // values from 0 to 3000, with step 50ms
	duration: 950, // values from 0 to 3000, with step 50ms
	easing: "ease-out-cubic", // default easing for AOS animations
	once: true, // whether animation should happen only once - while scrolling down
	mirror: false, // whether elements should animate out while scrolling past them
})

/* Accordion */
const accordion = document.getElementsByClassName("faq__btn")
var i

for (i = 0; i < accordion.length; i++) {
	accordion[i].addEventListener("click", function () {
		this.classList.toggle("faq__btn--active")

		const panel = this.nextElementSibling
		if (panel.style.maxHeight) {
			panel.style.maxHeight = null
		} else {
			panel.style.maxHeight = panel.scrollHeight + "px"
		}
	})
}
