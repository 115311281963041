class Nav {
	constructor() {
		this.prevScrollpos = 0
		this.scrolled = false
		this.direction = null
		this.onScroll = this.onScroll.bind(this)
	}

	init() {
		this.prevScrollpos = window.pageYOffset
		this.scrolled = false
		this.direction = null
		this.navBar = document.getElementById("navbar")
	}

	start() {
		window.addEventListener("scroll", this.onScroll, { passive: false })
	}
	stop() {
		window.removeEventListener("scroll", this.onScroll, { passive: false })
	}

	onScroll() {
		const y = window.pageYOffset

		if (y >= 88) {
			// if not already scrolled
			if (this.scrolled === false) {
				this.scrolled = true
			}

			// get scroll direction
			const direction = this.prevScrollpos > y ? "up" : "down"

			// if scroll direction has changed, update navBar position
			if (direction !== this.direction) {
				this.direction = direction
				this.navBar.style.top = `${direction === "up" ? 0 : -134}px`
			}

			// save scroll position
			this.prevScrollpos = y
		} else {
			// if scrolled
			if (this.scrolled === true) {
				this.navBar.style.top = "0px"
				this.scrolled = false
			}
		}
	}
}

export default Nav
